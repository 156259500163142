body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'RobotoMono';
  src: url('./static/font/RobotoMono-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Montserrat';
  src: url('./static/font/Montserrat-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Opensans';
  src: url('./static/font/OpenSans-VariableFont_wdth\,wght.ttf');
}
@font-face {
  font-family: 'WorkSans';
  src: url('./static/font/WorkSans-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'LobSter';
  src: url('./static/font/Lobster-Regular.ttf');
}
@font-face {
  font-family: 'Poppins';
  src: url('./static/font/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'PoppinsBold';
  src: url('./static/font/Poppins-ExtraBold.ttf');
}
/* Select the scrollbar track */
::-webkit-scrollbar-track {
  /* Change the background color */
  background-color: #f1f1f1;
}

/* Select the scrollbar thumb */
::-webkit-scrollbar-thumb {
  /* Change the background color */
  background-color: #888;

  /* Change the size of the thumb */
  height: 30px;
  width: 5px;
}

#journal-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
#journal-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /*background: red;*/
}
#journal-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  /*outline: 1px solid slategrey;*/
}
